<template>
  <div class="page-wrapper">
    <art-detail ref="artDetail" detail-type="expert" @hide-submit="handleIsHideSubmit"></art-detail>
    <div v-if="!hideSubmitReview" class="write-review-box">
      <h3 class="m-b-30 m-t-10" style="font-size: 28px;">对作品进行评审</h3>
      <div class="write-area">
        <el-input v-model="review" type="textarea" rows="6" resize="none"
                  placeholder="这里写下对作品的评价和建议"></el-input>
        <div class="btn-group">
          <el-button class="btn-pass" :loading="loading"
                     type="primary"
                     @click="handleSubmitReviewResult('pass')">提交并通过</el-button>
          <el-button class="btn-not-pass" :loading="loading"
                     type="primary"
                     @click="handleSubmitReviewResult('notpass')">驳回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import ArtDetail from "@/components/ArtDetail/index";
  import {submitExpertReviewResult} from "@/page-home/api/expert";
  export default {
    name: "ExpertReviewDetail",
    components: {ArtDetail},
    data() {
      return {
        loading: false,
        review: '',
        hideSubmitReview: true
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.userInfo
      })
    },

    methods: {
      handleSubmitReviewResult(type) {
        if (this.loading) return
        if (!this.review) {
          this.$message.error('请填写对作品的评价和建议')
          return
        }
        const payload = {
          artId: this.$refs['artDetail'].artId,
          version: this.$refs['artDetail'].version,
          content: this.review
        }
        if (type === 'pass') {
          payload.reviewResult = 1
        } else {
          payload.reviewResult = 0
        }
        this.loading = true
        submitExpertReviewResult(payload).then(res => {
          this.$message.success('提交成功')
          this.$router.go(-1)
        }).catch(err => {
          this.$message.error(err.msg)
        }).finally(() => {
          this.loading =false
        })
      },

      handleIsHideSubmit(val) {
        this.hideSubmitReview = val
      }
    },

    mounted() {

    },
  }
</script>

<style lang="scss" scoped>
  .page-wrapper {
    padding: 30px;
  }

  .write-review-box {
    max-width: 1200px;
    margin: 0 auto;
    padding: 18px 16px;
    .write-area {
      position: relative;
      height: 200px;
      padding-top: 8px;
      background-color: #F1F1F2;
      .btn-group {
        position: absolute;
        right: 20px;
        bottom: 10px;
      }
      /deep/ {
        .el-textarea__inner {
          background-color: #F1F1F2;
          border: none;
        }
      }
    }
  }

  .btn-pass {
    margin-right: 40px;
    background: #004DF8;
    border-color: #004DF8;
    border-radius: 5px;
  }
  .btn-not-pass {
    background: #FA012A;
    border-color: #FA012A;
    border-radius: 5px;
  }
</style>
